import React, { useEffect, useState } from "react";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import Drawer from "./drawer";
import { Link } from "react-router-dom";
import Button from "../../button";
import Logo from "../../logo";
import { RxCross2 } from "react-icons/rx";

interface NavbarProps {}

const Navbar: React.FC<NavbarProps> = () => {
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [open]);

  return (
    <>
      <Drawer open={open} setOpen={setOpen} />
      <nav className="py-0 lg:py-1 sticky inset-0 backdrop-blur-xl w-full z-50 h-full bg-black/50">
        <div className="container flex items-center h-full py-2 md:py-3 lg:py-4 justify-between">
          <div className="flex items-center space-x-4">
            <div>
              <Logo />
            </div>
          </div>
          <div className="flex items-center">
            <ul className="pr-4 hidden gap-x-4 lg:flex text-textDark space-x-4 xl:space-x-8 dark:text-white items-center cursor-pointer">
              {["skills", "projects", "experience"].map((item: string) => (
                <li
                  className={`relative font-medium text-base link py-1 rounded-md`}
                >
                  <a href={`#${item}`} className="capitalize">
                    {item}
                  </a>
                </li>
              ))}
              <li>
                <a
                  href="/#contact"
                  className="relative inline-flex text-md group"
                >
                  <Button>Contact Me</Button>
                </a>
              </li>
            </ul>

            <div
              onClick={() => setOpen(!open)}
              className="cursor-pointer flex lg:hidden bg-theme-purple p-2 rounded-md "
            >
              {open ? (
                <RxCross2 className="text-2xl" />
              ) : (
                <HiOutlineMenuAlt3 className="text-2xl" />
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
