import React, { FC } from "react";

interface ButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  type?: string;
}

const Button: FC<ButtonProps> = (props) => {
  return (
    <button
      disabled={props.disabled}
      className="transition-all duration-500 bg-gradient-to-r from-theme-purple to-theme-purple/50 hover:from-theme-purple/50 hover:to-theme-purple shadow-button hover:shadow-button-hover py-3 px-6 lg:px-8 rounded-full text-white font-medium"
    >
      {props.children}
    </button>
  );
};

export default Button;
