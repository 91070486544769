import React, { FC, useState } from "react";
import SKillCard from "./skill-card";
import { FaReact } from "react-icons/fa";
import { RiTailwindCssFill } from "react-icons/ri";
import { IoLogoJavascript } from "react-icons/io5";
import { FaHtml5 } from "react-icons/fa";
import { IoLogoCss3 } from "react-icons/io5";
import { FaBootstrap } from "react-icons/fa";
import Tabs from "./tabs";

interface SkillsProps {
  // Your prop types here
}

type Tab = "web-developer" | "data-analyst" | "data-engineer";

const Skills: FC<SkillsProps> = (props) => {
  const [tab, setTab] = useState<Tab>("web-developer");
  console.log(tab, "tab");
  return (
    <section
      id="skills"
      className="relative flex flex-col gap-y-8  pt-[8vh] lg:pt-[15vh]"
    >
      <div className="absolute inset-0 z-10 w-[20rem] h-[20rem] circle opacity-80"></div>
      <div className="absolute right-1/2 translate-x-1/2 lg:translate-x-0  lg:right-0 bottom-0 z-10 w-[20rem] h-[20rem] circle opacity-80"></div>
      <div className="flex container relative z-20 flex-col gap-y-4 text-center items-center">
        <h1 className="text-3xl lg:text-6xl leading-normal w-fit bg-gradient-to-r from-theme-purple to-theme-white inline-block text-transparent bg-clip-text font-bold">
          My Skills
        </h1>
      </div>
      <div className="container z-20 lg:py-2 flex justify-center">
        <Tabs
          setTab={setTab}
          tab={tab}
          data={["Web Developer", "Data Analyst", "Data Engineer"]}
        />
      </div>
      {tab === "web-developer" ? (
        <div className="grid container relative z-20 grid-cols-2 md:grid-cols-4 lg:grid-cols-6 2xl:px-[1vw] gap-x-6">
          <SKillCard
            icon={<FaHtml5 className="text-[#DD4B25]" />}
            title="HTML"
            level={"experienced"}
          />
          <SKillCard
            icon={<IoLogoCss3 className="text-[#005FD8]" />}
            title="CSS"
            level={"intermediate"}
          />

          <SKillCard
            icon={<RiTailwindCssFill className="text-[#38BDF8]" />}
            title="Tailwind CSS"
            level={"intermediate"}
          />
          <SKillCard
            icon={<FaBootstrap className="text-[#8112F6]" />}
            title="Bootstrap"
            level={"intermediate"}
          />
          <SKillCard
            icon={<IoLogoJavascript className="text-[#EFD81D]" />}
            title="Javascript"
            level={"beginner"}
          />
          <SKillCard
            icon={<FaReact className="text-[#5ED3F3]" />}
            title="React JS"
            level={"beginner"}
          />
        </div>
      ) : (
        <></>
      )}
    </section>
  );
};

export default Skills;
