import React, { FC } from "react";
import { Link } from "react-router-dom";
import Button from "../../button";

interface DrawerProps {
  open: boolean;
  setOpen: Function;
}

const Drawer: FC<DrawerProps> = (props) => {
  return (
    <div
      className={`flex lg:hidden transition-all duration-300 fixed inset-0 w-full z-50 bg-black/10 top-[64px] h-[calc(100vh-64px)] backdrop-blur-lg ${
        props.open ? "top-[64px] " : "!top-[-100vh]"
      }`}
    >
      <ul className="px-4 py-8 gap-y-4 flex flex-col dark:text-white cursor-pointer">
        {["skills", "projects", "experience"].map((item: string) => (
          <li
            onClick={() => props.setOpen(false)}
            className={`relative font-medium text-xl link py-1 rounded-md`}
          >
            <a href={`#${item}`} className="capitalize">
              {item}
            </a>
          </li>
        ))}
        <li>
          <a
            onClick={() => props.setOpen(false)}
            href="/#contact"
            className="relative inline-flex text-md group"
          >
            <Button>Contact Me</Button>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Drawer;
