import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Layout from "./components/layout";
import Hero from "./components/hero";
import Projects from "./components/projects";
import Experience from "./components/experience";
import ContactForm from "./components/contact-form";
import Skills from "./components/skills";

function App() {
  return (
    <Layout>
      <Hero />
      <Skills />
      <Projects />
      <Experience />
      <ContactForm />
    </Layout>
  );
}

export default App;
