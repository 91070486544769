import React, { FC } from "react";
import Button from "../button";

interface HeroProps {
  // Your prop types here
}

const Hero: FC<HeroProps> = (props) => {
  return (
    <section
      id="#home"
      className="container min-h-[calc(100vh-90px)] grid grid-cols-1  lg:grid-cols-2"
    >
      <div className="absolute inset-0 z-10 w-[20rem] h-[20rem] circle opacity-80"></div>
      <div className="flex flex-col items-center h-full lg:items-start justify-center gap-y-4 lg:gap-y-6">
        <h1 className="text-xl lg:text-4xl text-theme-white  font-bold">
          I am Krishna
        </h1>
        <div className="flex flex-col items-center lg:items-start gap-y-2 lg:gap-y-4">
          <h1 className="text-3xl text-center lg:text-left lg:text-6xl w-fit bg-gradient-to-r from-theme-purple to-theme-white inline-block text-transparent bg-clip-text font-bold">
            Web Developer &
          </h1>
          <h1 className="text-3xl text-center lg:text-left lg:text-7xl w-fit bg-gradient-to-r from-theme-purple to-theme-white inline-block text-transparent bg-clip-text font-bold">
            UI/UX Designer
          </h1>
        </div>
        <p className="text-base lg:text-xl max-w-[35rem] text-center lg:text-left text-theme-white">
          I break down complex user experinece problems to create integritiy
          focussed solutions that connect billions of people
        </p>
        <Button>Download CV</Button>
      </div>
      <div className="flex container relative items-center justify-center">
        <div className="aspect-[0.95] relative z-20 rounded-3xl transition-all duration-300 rotate-[7deg] hover:rotate-0 overflow-hidden max-w-[28rem] border-2 border-theme-purple/50 hover:border-theme-purple">
          <img
            src="/hero-section.jpg"
            className="w-full h-full object-cover"
            alt="hero-img"
          />
        </div>
        <div className="absolute bottom-1/2 z-10 w-[20rem] h-[20rem] circle opacity-80"></div>
        <div className="absolute bottom-[20%] left-0 z-10 w-[20rem] h-[20rem] circle opacity-80"></div>
      </div>
    </section>
  );
};

export default Hero;
