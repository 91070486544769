import React, { FC } from "react";
import Navbar from "./navbar";
import Footer from "./footer";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: FC<LayoutProps> = (props) => {
  return (
    <div className="bg-theme-bg font-sora min-h-screen">
      <Navbar />
      {props.children}
      <Footer />
    </div>
  );
};

export default Layout;
