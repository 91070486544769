import React, { FC } from "react";

interface TabsProps {
  data: string[];
  tab: Tab;
  setTab: Function;
}
type Tab = "web-developer" | "data-analyst" | "data-engineer";

const Tabs: FC<TabsProps> = (props) => {
  const handleClick = (item: any) => {
    props.setTab(item.toLocaleLowerCase().replace(" ", "-"));
    console.log(item.toLocaleLowerCase().replace(" ", "-"));
  };
  return (
    <div className="rounded-full bg-theme-black shadow-sm flex">
      {props.data.map((item) => (
        <div
          key={item}
          onClick={() => handleClick(item)}
          className={`rounded-full px-3 whitespace-nowrap lg:px-8 text-sm lg:text-base cursor-pointer py-3 ${
            item.toLocaleLowerCase().replace(" ", "-") === props.tab
              ? "bg-gradient-to-r from-theme-purple via-theme-purple to-[#2D1659]"
              : "bg-transparent"
          }`}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
