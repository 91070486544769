import React, { FC } from "react";
import Button from "../button";
import { IoMailOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
import { RiMessengerLine } from "react-icons/ri";
import { FaWhatsapp } from "react-icons/fa";
import { useForm, ValidationError } from "@formspree/react";

interface ContactFormProps {
  // Your prop types here
}

const ContactForm: FC<ContactFormProps> = (props) => {
  const [state, handleSubmit] = useForm("xrbzgoaz");

  console.log(state, "::::state::::");
  return (
    <section
      id="contact"
      className="py-[5vh] relative 2xl:py-[12vh] bg-theme-black"
    >
      {/* <div className="absolute inset-0 z-10 w-[20rem] h-[20rem] circle opacity-80"></div>{" "} */}
      <div className="container relative z-20 grid grid-cols-1 lg:grid-cols-2 gap-6">
        {state.succeeded ? (
          <div className="flex items-center justify-center rounded-2xl flex-col gap-y-4 bg-card-bg shadow-xl p-12">
            <img src="/thanks-img.webp" alt="img" className="max-w-64" />
            <h1 className="text-5xl w-fit bg-gradient-to-r leading-normal from-theme-purple to-theme-white inline-block text-transparent bg-clip-text font-bold">
              Thank you!
            </h1>
            <p className="text-lg max-w-md text-center text-theme-white">
              Your message has been sent successfully! Expect to hear back
              soon—exciting things are on the way! 🎉✨
            </p>
          </div>
        ) : (
          <div className="rounded-2xl flex flex-col gap-y-4 bg-card-bg shadow-xl p-5 lg:p-12">
            <h1 className="text-3xl lg:text-5xl w-fit bg-gradient-to-r leading-normal from-theme-purple to-theme-white inline-block text-transparent bg-clip-text font-bold">
              Let’s work together!
            </h1>
            <p className="text-base lg:text-lg text-theme-white">
              I design and code beautifully simple things and i love what i do.
              Just simple like that!
            </p>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-y-4 pt-2"
            >
              <div className="grid grid-cols-2 gap-4">
                <input
                  type="text"
                  className="input"
                  id="firstName"
                  required
                  name="firstName"
                  placeholder="First Name*"
                />

                <input
                  type="text"
                  className="input"
                  id="lastName"
                  required
                  name="lastName"
                  placeholder="Last Name*"
                />
              </div>
              <input
                type="email"
                required
                className="input"
                name="email"
                id="email"
                placeholder="Email Address*"
              />
              <input
                type="number"
                required
                className="input"
                name="phone"
                id="phone"
                placeholder="Phone Number*"
              />
              <textarea
                name="message"
                id="message"
                placeholder="Message..."
                rows={7}
                className="input"
              ></textarea>
              <button type="submit" className="flex justify-end">
                <Button disabled={state.submitting}>
                  {state.submitting ? "Sending Message..." : "Send Message"}
                </Button>
              </button>
            </form>
          </div>
        )}
        <div className="flex flex-col h-fit gap-y-6 lg:sticky inset-0 top-[100px] left-0">
          <div className="rounded-2xl relative shadow-xl bg-gradient-to-r from-card-bg via-card-bg to-card-bg  transition duration-200 hover:to-theme-purple/70 flex items-center gap-x-4 p-4 lg:p-6">
            <div className="rounded-full bg-theme-purple aspect-square flex items-center justify-center text-xl lg:text-2xl w-10 lg:w-14">
              <IoMailOutline className="text-white " />
            </div>
            <div className="flex flex-col gap-y-1 pt-[1px]">
              <p className="text-sm lg:text-base text-theme-white/70">
                Email Address
              </p>
              <Link
                to="mailto:gudladana.krishna999@gmail.com"
                className="text-theme-white text-base lg:text-xl font-semibold"
              >
                gudladana.krishna999@gmail.com
              </Link>
            </div>
            <div className="ml-auto absolute lg:relative top-4 lg:right-0 right-4 lg:top-0 flex items-center">
              <Link to="mailto:gudladana.krishna999@gmail.com">
                <FaArrowRight className="text-lg lg:text-3xl" />
              </Link>
            </div>
          </div>
          <div className="rounded-2xl relative shadow-xl bg-gradient-to-r from-card-bg via-card-bg to-card-bg transition duration-200 hover:to-theme-purple/70 flex items-center gap-x-4 p-4 lg:p-6">
            <div className="rounded-full bg-theme-purple aspect-square flex items-center justify-center text-xl lg:text-2xl w-10 lg:w-14">
              <FaWhatsapp className="text-white" />
            </div>
            <div className="flex flex-col gap-y-1 pt-[1px]">
              <p className="text-sm lg:text-base text-theme-white/70">
                WhatsApp
              </p>
              <Link
                to="https://api.whatsapp.com/send?phone+917032482821"
                target="_blank"
                rel="noreferrer"
                className="text-theme-white text-base lg:text-xl font-semibold"
              >
                Contact via WhatsApp
              </Link>
            </div>
            <div className="ml-auto absolute lg:relative top-4 lg:right-0 right-4 lg:top-0 flex items-center">
              <Link
                to="https://api.whatsapp.com/send?phone+917032482821"
                target="_blank"
                rel="noreferrer"
              >
                <FaArrowRight className="text-lg lg:text-3xl" />
              </Link>
            </div>
          </div>

          <div className="rounded-2xl relative shadow-xl bg-gradient-to-r from-card-bg via-card-bg to-card-bg transition duration-200 hover:to-theme-purple/70 flex items-center gap-x-4 p-4 lg:p-6">
            <div className="rounded-full bg-theme-purple aspect-square flex items-center justify-center text-xl lg:text-2xl w-10 lg:w-14">
              <RiMessengerLine className="text-white" />
            </div>
            <div className="flex flex-col gap-y-1 pt-[1px]">
              <p className="text-sm lg:text-base text-theme-white/70">
                Messenger
              </p>
              <Link
                target="_blank"
                rel="noreferrer"
                to="https://m.me/KrishnaChaitanyaGudladana"
                className="text-theme-white text-base lg:text-xl font-semibold"
              >
                Krishna Gudladana
              </Link>
            </div>
            <div className="ml-auto absolute lg:relative top-4 lg:right-0 right-4 lg:top-0 flex items-center">
              <Link
                target="_blank"
                rel="noreferrer"
                to="https://m.me/KrishnaChaitanyaGudladana"
              >
                <FaArrowRight className="text-lg lg:text-3xl" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
