import React, { FC } from "react";

interface SKillCardProps {
  icon: React.ReactNode;
  level: "intermediate" | "experienced" | "beginner";
  title: string;
}

const SKillCard: FC<SKillCardProps> = (props) => {
  return (
    <div className="flex w-full flex-col gap-y-4 items-center">
      <div className="rounded-3xl w-full border border-theme-purple backdrop-blur-lg bg-theme-purple/30 p-8 flex flex-col gap-y-4 items-center">
        <span className="text-6xl lg:text-7xl">{props.icon}</span>
        <span className="text-base lg:text-lg capitalize font-medium text-theme-white/50">
          {props.level}
        </span>
      </div>
      <p className="text-base lg:text-lg font-medium text-theme-purple">
        {props.title}
      </p>
    </div>
  );
};

export default SKillCard;
