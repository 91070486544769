import React, { FC } from "react";

interface ExperienceProps {
  // Your prop types here
}

const Experience: FC<ExperienceProps> = (props) => {
  return <div></div>;
};

export default Experience;
