import React, { FC } from "react";

interface ProjectCardProps {
  title: string;
  description: string;
  imgUrl: string;
}

const ProjectCard: FC<ProjectCardProps> = (props) => {
  return (
    <div>
      <div className="px-5 lg:px-10 relative pt-5 lg:pt-10 rounded-xl bg-[#140C1C] shadow-lg">
        <img
          src={props.imgUrl}
          alt={"img"}
          className="w-full h-full object-fill shadow-sm"
        />
      </div>
      <div className="p-2 lg:p-5 -mt-12 z-20 relative">
        <div className="p-4 lg:p-6 flex flex-col gap-y-1 lg:gap-y-3 bg-gradient-to-r from-theme-purple via-theme-purple rounded-xl to-[#2D1659] ">
          <p className="text-2xl lg:text-3xl font-bold text-white">
            {props.title}
          </p>
          <p className="text-sm lg:text-lg text-white">{props.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
