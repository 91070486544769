import React, { FC, Fragment } from "react";
import ProjectCard from "./project-card";

interface ProjectsProps {
  // Your prop types here
}

const Projects: FC<ProjectsProps> = (props) => {
  return (
    <section
      id="projects"
      className="relative flex flex-col gap-y-8 pt-[8vh] lg:pt-[15vh]"
    >
      <div className="flex container relative z-20 flex-col gap-y-4 text-center items-center">
        <h1 className="text-3xl lg:text-6xl leading-normal w-fit bg-gradient-to-r from-theme-purple to-theme-white inline-block text-transparent bg-clip-text font-bold">
          My Projects
        </h1>
        <p className="text-base lg:text-lg max-w-[40rem] text-theme-white">
          Showcasing My Technical Proficiency
        </p>
      </div>
      <div className="relative">
        <div className="absolute inset-0 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 w-[20rem] h-[20rem] circle opacity-90"></div>
        <div className="grid container relative z-20 gap-4 lg:gap-6 grid-cols-1 lg:grid-cols-2">
          {data.map((item: any, index: number) => (
            <Fragment key={index}>
              <ProjectCard {...item} />
            </Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

const data: { title: string; imgUrl: string; description: string }[] = [
  {
    title: "Resume Builder ",
    description:
      "Built a resume builder website using reactjs/nextjs and tailwindCSS",
    imgUrl: "/projects/1.png",
  },
  {
    title: "Resume Builder ",
    description:
      "Built a resume builder website using reactjs/nextjs and tailwindCSS",
    imgUrl: "/projects/1.png",
  },
  {
    title: "Resume Builder ",
    description:
      "Built a resume builder website using reactjs/nextjs and tailwindCSS",
    imgUrl: "/projects/1.png",
  },
  {
    title: "Resume Builder ",
    description:
      "Built a resume builder website using reactjs/nextjs and tailwindCSS",
    imgUrl: "/projects/1.png",
  },
];

export default Projects;
