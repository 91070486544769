import React, { FC } from "react";
import { Link } from "react-router-dom";

interface LogoProps {
  // Your prop types here
}

const Logo: FC<LogoProps> = (props) => {
  return (
    <div className="flex text-lg font-semibold items-center gap-x-4 ">
      <img src="/logo.svg" className="max-h-12" />
      <Link
        to="mailto:gudladana.krishna999@gmail.com"
        className="lg:flex hidden"
      >
        gudladana.krishna999@gmail.com
      </Link>
    </div>
  );
};

export default Logo;
