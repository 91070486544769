import React, { FC } from "react";
import Logo from "../../logo";
import { Link } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoMailOutline } from "react-icons/io5";

interface FooterProps {
  // Your prop types here
}

const Footer: FC<FooterProps> = (props) => {
  return (
    <>
      <footer className="flex text-white flex-col gap-y-6 py-[4vh] 2xl:py-[6vh]">
        <div className="flex flex-col lg:flex-row gap-6 container items-center w-full justify-between">
          <Logo />
          <div className="flex gap-x-4 items-center">
            <Link
              to="https://www.instagram.com/krishna_chaitanya_gudladana/"
              target="_blank"
              rel="noreferrer"
              className="w-10 lg:w-12 h-10 lg:h-12 duration-300 flex items-center text-xl lg:text-2xl justify-center aspect-square rounded-full border border-theme-purple hover:bg-theme-purple hover:text-white text-theme-purple"
            >
              <FaInstagram />
            </Link>
            <Link
              to="https://www.facebook.com/KrishnaChaitanyaGudladana/"
              target="_blank"
              rel="noreferrer"
              className="w-10 lg:w-12 h-10 lg:h-12 duration-300 flex items-center text-xl lg:text-2xl justify-center aspect-square rounded-full border border-theme-purple hover:bg-theme-purple hover:text-white text-theme-purple"
            >
              <FaFacebookF />
            </Link>
            <Link
              to="https://twitter.com/krishnagudlada"
              target="_blank"
              rel="noreferrer"
              className="w-10 lg:w-12 h-10 lg:h-12 duration-300 flex items-center text-xl lg:text-2xl justify-center aspect-square rounded-full border border-theme-purple hover:bg-theme-purple hover:text-white text-theme-purple"
            >
              <FaXTwitter />
            </Link>
            <Link
              to="mailto:gudladana.krishna999@gmail.com"
              target="_blank"
              rel="noreferrer"
              className="w-10 lg:w-12 h-10 lg:h-12 duration-300 flex items-center text-xl lg:text-2xl justify-center aspect-square rounded-full border border-theme-purple hover:bg-theme-purple hover:text-white text-theme-purple"
            >
              <IoMailOutline />
            </Link>
          </div>
        </div>
      </footer>
      <div className="bg-theme-black py-4 text-center lg:text-left text-white text-sm lg:text-lg">
        <div className="container">
          &copy; {new Date().getFullYear()} Krishna Gudladana | All rights
          reserved
        </div>
      </div>
    </>
  );
};

export default Footer;
